<template>
  <div class="text-center">
    <b-button
      type="button"
      class="px-4 login-azure-ad-btn"
      @click="loginAzure"
      :disabled="isDisable"
      >
      <img
        src="@/assets/icons/windows.png"
        class="icon-windows"
      />
      Log in with Azure AD</b-button
    >
    <div class="text-center mt-3" v-if="error">
      <p class="text-danger mb-0">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import auth from '@/plugins/msal/auth'
import graph from '@/plugins/msal/graph'

export default {
  props: {
    isDisable: {
      required: false,
      type: Boolean,
    },
    clientId: {
      required: true,
      type: String,
    },
    endpoint: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      registerTypeId: 6,
      // User account object synced with MSAL getAccount()
      user: {
        username: '',
        idToken: '',
      },
      // Access token fetched via MSAL for calling Graph API
      accessToken: '',
      // Details fetched from Graph API, user object and photo
      graphDetails: null,
      graphPhoto: null,
      // Visibility toggles for the three details modal popups
      showUserDetails: false,
      showGraphDetails: false,
      showTokenDetails: false,
      error: ""
    }
  },
  created () {
    let callback = `${window.location.origin}/azureauth/login`
    auth.configure(this.clientId, this.endpoint, callback)

    // Restore any cached or saved local user
    // this.user = auth.user()
    // console.log(`configured ${auth.isConfigured()}`)
  },
  methods: {
    async callGraph() {
      if (!this.user) {
        return
      }

      try {
        this.graphDetails = await graph.getSelf()
        // this.graphPhoto = await graph.getPhoto()
        this.accessToken = await graph.getAccessToken()
        this.updateData()
      } catch (err) {
        this.error = err
      }
    },
    // Update user from MSAL
    async updateUser() {
      this.user = {...auth.user()}
      // this.user.idToken = await auth.getIdToken();
    },
    // Remove locally held user details, is same as logout
    shallowLogout() {
      this.user = null
      this.graphDetails = null
      this.userDetails = null
      this.graphPhoto = null
      this.accessToken = null
      // auth.clearLocal()
      this.updateData()
    },
    // Full logout local & server side
    fullLogout() {
      auth.logout()
      this.shallowLogout();
    },
    // Get an access token and call graphGetSelf & graphGetPhoto
    async fetchGraphDetails() {
      if (!this.user) {
        return
      }

      try {
        this.graphDetails = await graph.getSelf()
        // this.graphPhoto = await graph.getPhoto()
        this.accessToken = graph.getAccessToken()
        this.updateData();
      } catch (err) {
        this.error = err
      }
    },
    loginAzure: async function () {
      try {
        await auth.login()
        await this.updateUser()
        // await this.fetchGraphDetails() 
        await this.updateData();
        await this.pairAzure()
      } catch (err) {
        this.error = err.toString()
      }
    },
    async updateData(){
      await this.$emit('resData', this.accessToken, this.user, this.graphDetails)
    },
    pairAzure: async function () {
      let form = {
        registerTypeId: this.registerTypeId,
        SocialProvider: "azure",
        Email: this.user.username,
        id_token: this.user.idToken
      }
      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/PairAzure`,
        null,
        this.$headers,
        form
      );

      if (data.result == 1) {
        this.submitLogin();
      } else {
        // this.fullLogout();
        this.error = data.message;
      }
    },
    submitLogin: async function () {
      let form = {
        SocialProvider: "azure",
        Email: this.user.username,
        id_token: this.user.idToken
      }
      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/LoginAzureExternal`,
        null,
        this.$headers,
        form
      );

      if (data.result == 1) {
        this.error = "";
        await this.$cookies.set("token", data.detail, 60 * 60 * 24 * 30);
        await this.$cookies.set("back_office_admin_is_login_azure", true, 60 * 60 * 24 * 30);
        window.location.href = "/";
      } else {
        this.error = data.message;
      }
    },
  },
};
</script>

<style scoped>
.login-azure-ad-btn {
  padding: 8px 35px !important;
  font-size: 15px;
  border-radius: 10px !important;
  background: #066398;
  border: #066398 solid 1px;
  color: white;
  text-align: center;
  margin: auto;
  transition: 0.3s;
}
.login-azure-ad-btn:hover {
  color: white;
  background: #005da6;
  border: #005da6 solid 1px;
}
.icon-windows {
  margin-right: 5px;
  height: 25px;
}
@media (max-width: 600px) {
  .login-azure-ad-btn {
    width: 100% !important;
  }
}
</style>
